<template>
  <div>
    <p class="display-1 mt-10">Mintatantervek kezelése</p>
    <v-expansion-panels class="mt-5" v-model="panel" >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-magnify</v-icon>
            Szemeszter Tematika megtekintés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :lg="3" :md="3" :sm="3">
              <v-combobox
                label="Félév"
                color="uni_blue2"
                item-color="uni_blue2"
                :items="semesters"
                v-model="tableSearch.semester"
                item-text="felev"
                item-value="id"
                @change="semesterChange"
              >
              </v-combobox>
            </v-col>
            <v-col :cols="12" :lg="3" :md="3" :sm="3">
              <v-text-field label="Szak" v-model="search"></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mt-3">
      <v-data-table :headers="headers" :items="filter" height="500">
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="beforeDeleteMintaterv(item.id)"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Törlés</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="watchDescription(item, 1)"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>Magyar Szöveg megtekintés</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="watchDescription(item, 2)"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>Angol Szöveg megtekintés</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="showDescription" width="50%" scrollable>
        <v-card class="p-3">
          <v-card-text>
            <p class="sticky bg-white border-bottom">
              {{ selectedDescription.felev }} - {{ selectedDescription.kar }} -
              {{ selectedDescription.szak }} -
            </p>
            <p
              v-html="selectedDescription.leiras"
              class="m-0"
              v-if="selectedDesNumber == 1"
            ></p>
            <p
              v-html="selectedDescription.leiras_en"
              class="m-0"
              v-if="selectedDesNumber == 2"
            ></p>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showDelete" width="50%" class="scrollbar">
        <v-card>
          <v-card-title class="text-h5">Figyelmeztetés</v-card-title>
          <v-card-text
            >Biztosan Törölni szeretné a tanulmányi tájékoztatót?</v-card-text
          >
          <v-card-actions class="justify-end">
            <v-btn
              color="uni_blue"
              class="text-light m-1"
              @click="deleteMintaterv"
              >Igen</v-btn
            >
            <v-btn
              color="uni_blue"
              class="text-light m-1"
              @click="showDelete = false"
              >Nem</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    panel: [1],
    search: "",
    tableSearch: {
      semester: "",
      department: "",
      faculty: "",
    },
    semesters: [],
    departments: [],
    faculties: [],
    tableTajekoztatok: [],
    headers: [
      { text: "Szemeszter", value: "felev" },
      { text: "Típus", value: "tipus" },
      { text: "Kar Kód", value: "kar_kod" },
      { text: "Kar", value: "kar" },
      { text: "Szak kód", value: "szak_kod" },
      { text: "Szak", value: "szak" },
      { text: "Rögzített", value: "created" },
      { text: "Megjegyzés", value: "megjegyzes" },
      {
        text: "",
        value: "actions",
        sortable: false,
        width: "170px",
        align: "right",
      },
    ],
    selectedDescription: "",
    showDelete: false,
    showDescription: false,
    selectedDelete: -1,
    selectedDesNumber: 0,
  }),
  mounted() {
    this.getsemesters();
  },
  computed: {
    filter() {
      var a = this.tableTajekoztatok.filter(
        (item) =>
          item.szak.toLowerCase().indexOf(this.search.toLocaleLowerCase()) !==
          -1
      );
      return a;
    },
  },
  methods: {
    beforeDeleteMintaterv(id) {
      this.selectedDelete = id;
      this.showDelete = true;
    },
    async deleteMintaterv() {
      try {
        const post = {
          id: this.selectedDelete,
        };
        const response = await this.$http.post("/deleteTajekoztato", post);
        this.searchTable();
        this.showDelete = false;
      } catch (e) {
        console.error(e);
      }
    },
    watchDescription(item, number) {
      this.selectedDescription = item;
      this.selectedDesNumber = number;
      this.showDescription = true;
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    async getsemesters() {
      try {
        const response = await this.$http.post("/adminsemester/0");
        if (response.semesters.length == 0) {
          window.alert("Nem sikerült lekérni az adatokat.");
        } else {
          this.semesters = response.semesters;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async searchTable() {
      try {
        const response = await this.$http.post(
          "/searchTable",
          this.tableSearch
        );
        this.tableTajekoztatok = response.tajekoztatok;
      } catch (e) {
        console.error(e);
      }
    },
    async semesterChange() {
      try {
        const response = await this.$http.post(
          "adminsemchange",
          this.tableSearch
        );
        this.tableTajekoztatok = response.tajekoztatok;
        this.departments = response.departments;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style>
</style>