var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"display-1 mt-10"},[_vm._v("Mintatantervek kezelése")]),_c('v-expansion-panels',{staticClass:"mt-5",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Szemeszter Tematika megtekintés ")],1)]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"lg":3,"md":3,"sm":3}},[_c('v-combobox',{attrs:{"label":"Félév","color":"uni_blue2","item-color":"uni_blue2","items":_vm.semesters,"item-text":"felev","item-value":"id"},on:{"change":_vm.semesterChange},model:{value:(_vm.tableSearch.semester),callback:function ($$v) {_vm.$set(_vm.tableSearch, "semester", $$v)},expression:"tableSearch.semester"}})],1),_c('v-col',{attrs:{"cols":12,"lg":3,"md":3,"sm":3}},[_c('v-text-field',{attrs:{"label":"Szak"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filter,"height":"500"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.beforeDeleteMintaterv(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.watchDescription(item, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Magyar Szöveg megtekintés")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.watchDescription(item, 2)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Angol Szöveg megtekintés")])])]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"50%","scrollable":""},model:{value:(_vm.showDescription),callback:function ($$v) {_vm.showDescription=$$v},expression:"showDescription"}},[_c('v-card',{staticClass:"p-3"},[_c('v-card-text',[_c('p',{staticClass:"sticky bg-white border-bottom"},[_vm._v(" "+_vm._s(_vm.selectedDescription.felev)+" - "+_vm._s(_vm.selectedDescription.kar)+" - "+_vm._s(_vm.selectedDescription.szak)+" - ")]),(_vm.selectedDesNumber == 1)?_c('p',{staticClass:"m-0",domProps:{"innerHTML":_vm._s(_vm.selectedDescription.leiras)}}):_vm._e(),(_vm.selectedDesNumber == 2)?_c('p',{staticClass:"m-0",domProps:{"innerHTML":_vm._s(_vm.selectedDescription.leiras_en)}}):_vm._e()])],1)],1),_c('v-dialog',{staticClass:"scrollbar",attrs:{"width":"50%"},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Figyelmeztetés")]),_c('v-card-text',[_vm._v("Biztosan Törölni szeretné a tanulmányi tájékoztatót?")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-light m-1",attrs:{"color":"uni_blue"},on:{"click":_vm.deleteMintaterv}},[_vm._v("Igen")]),_c('v-btn',{staticClass:"text-light m-1",attrs:{"color":"uni_blue"},on:{"click":function($event){_vm.showDelete = false}}},[_vm._v("Nem")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }